@font-face {
  font-family: 'Archivo-Bold';
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/Archivo-Bold.ttf") format("truetype");
}

@font-face {
  font-family: 'Archivo-Regular';
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/Archivo-Regular.ttf") format("truetype");
}

@font-face {
  font-family: 'LIQUIDO-Reguar';
  src: url("../fonts/LIQUIDO-Reguar.otf") format("truetype");
}

@font-face {
  font-family: 'LIQUIDO-Fluid';
  src: url("../fonts/LIQUIDO-Fluid.otf") format("truetype");
}

.contact-page {
  text-align: center;
}

.scroll-for-more-wrap {
  opacity: 1;
  position: absolute;
  z-index: 999999;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.scroll-for-more-wrap p {
  font-family: "Archivo-Regular";
  font-weight: 700;
  text-transform: uppercase;
  color: #FFF;
  padding-left: 16px;
}

.scroll-for-more-inner {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: calc(100% - 24px);
}

.thumbnails {
  display: block;
  width: 100vw;
  height: 100vh;
  overflow: hidden scroll;
}

.thumbnail-row {
  display: flex;
}

.thumbnails .card{
  flex: 50%;
  height: 50vh;
  flex: 1;
  width: 100vw;
  position: relative;
  transition: all .1s ease-out;
  opacity: .8;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  overflow: hidden;
}
.thumbnails .card video{
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
}
.thumbnails .card:hover{
  opacity: 1;
}

.thumbnails .card:hover h1 {
  font-family: LIQUIDO-Reguar;
}

aside .single-director-name {
  font-family: Archivo-Regular;
  font-weight: 700;
  text-transform: uppercase;
  word-break: keep-all;
  white-space: nowrap;
  color: white;
}

aside .single-director-name:hover {
  background-color: transparent !important;
  opacity: .7 !important;
}

aside .back-btn {
  padding-top: 5px;
  margin-right: 15px;
  width: 35px;
  filter: invert(100);
  opacity: .7;
  transition: all .3s ease-out;
}
aside .back-btn:hover{
  opacity: 1;
}
aside .back-btn img{
  width: 100%;
  height: 25px;
  object-fit: cover;
}

.mobile-thumbnails .slide > video,
.slider .slide > video {
  object-fit: cover;
  object-position: center;
  width: 100vw;
  height: 100vh;
}

.slider-indicator {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  /* right: 56px; */
  right: 58px;
  display: flex;
  flex-direction: column;
  z-index: 1;
}

.slider-badge {
  height: 10px;
  width: 10px;
  border: 2px solid #ccc;
  background: transparent;
  border-radius: 100px;
  margin: 2px auto;
}

.slider-badge.checked {
  background: #FFF;
  border-color: #FFF;
}

.reactCircularLoader{
  display:flex;
  position: relative;
  align-content: space-around;
  justify-content: center;
}

.reactCircularLoaderPrimary{
  position: absolute;
  right: 0; top: 0;
  fill: none;
  stroke-linecap: round;
}

.reactCircularLoaderSecondary{
  position: absolute;
  right: 0; top: 0;
  fill: none;
  stroke-linecap: round;
}

.animate{
  stroke-dasharray: 242.6;
  animation: fill-animation 1s cubic-bezier(1,1,1,1) 0s infinite;
  animation-direction: reverse;
}

@keyframes fill-animation{
  0%{
    stroke-dasharray: 40 242.6;
    stroke-dashoffset: 0;
  }
  50%{
    stroke-dasharray: 141.3;
    stroke-dashoffset: 141.3;
  }
  100%{
    stroke-dasharray: 40 242.6;
    stroke-dashoffset: 282.6;
  }
}

.loading-overlay {
z-index: 999999999999;
height: 100%;
width: 100%;
position: fixed;
top: 0;
left: 0;
display: flex;
justify-content: center;
align-items: center;
}

body {
  margin: 0px;
  font-family: 'Archivo-Regular';
  padding: 0px;
  background-color: #000;
  scrollbar-gutter: stable both-edges;
  overflow: hidden;
  overflow-y: auto;
}

*,
.backToTop {
  cursor: none;
}


body:has(#root.contact) {
  overflow-y: hidden !important;
}

div.directors:not(#root) {
  height: calc(100vh - 164px);
  margin-top: 164px;
  overflow-y: auto;
}

@media all and (max-width: 780px) {
  body {
    overflow-y: auto;
    /* overflow-y: scroll !important; */
  }
}

/* width */
::-webkit-scrollbar {
  width: 0px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
  opacity: 0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: transparent;
  opacity: 0;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
  background: transparent;
}

#cursor {
  position: absolute;
  z-index: 99999;
  background-color: white;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  cursor: pointer;
  pointer-events: none;
  mix-blend-mode: exclusion;
  -webkit-transition: -webkit-transform 0.3s ease;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}

.loader-page {
  z-index: 9999999999;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  overflow: hidden;
}

.loader-page .glitch {
  -webkit-filter: invert(1);
          filter: invert(1);
}

.loader-page .socials {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.loader-page .socials a {
  margin: 0px 10px;
  opacity: .8;
}

.loader-page .socials img {
  height: 40px !important;
}

.loader-page::before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #000;
  z-index: -2;
}

.loader-page::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
  background: -webkit-gradient(linear, left top, right top, from(black), to(rgba(0, 0, 0, 0.3))), url(https://grainy-gradients.vercel.app/noise.svg);
  background: linear-gradient(to right, black, rgba(0, 0, 0, 0.3)), url(https://grainy-gradients.vercel.app/noise.svg);
  opacity: .5;
}

.loader-page .videoTag {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: -1;
  /* height: 100vh; */
  height: 100%;
  width: 100vw;
  object-fit: cover;
  opacity: .8;
  background-color: #000;
}

.loader-page .loader {
  background-position: center center;
  background-size: cover;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 100vh;
  overflow: hidden;
  -webkit-transition: all 0.8s cubic-bezier(0.72, 0.35, 0.57, 0.95);
  transition: all 0.8s cubic-bezier(0.72, 0.35, 0.57, 0.95);
  -webkit-transform: scale(1.2) skewY(0deg);
          transform: scale(1.2) skewY(0deg);
}

.loader-page .loader img {
  height: 70vh;
  opacity: 0;
  margin-top: 200px;
  -webkit-transition: all .3s ease-out;
  transition: all .3s ease-out;
  cursor: pointer;
}

@media all and (max-width: 1065px) and (min-width: 781px) {
  .menu-bb .items {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 16px;
  }
}

@media all and (min-width: 781px) {
  .directors-name {
    padding-top: 54px;
  }

  .socials-menu.mobile,
  .socials.mobile {
    display: none;
  }

  aside .socials {
    margin-top: 40px;
  }
}

@media all and (min-width: 736px) {
  .socials.mobile {
    margin-right: 36px;
    margin-top: 48px;
  }

  .mobile-menu-close {
    margin-top: 36px;
    margin-right: 12px;
    right: 36px;
  }
}

@media all and (max-width: 735px) {
  .mobile-menu-close {
    margin-top: 42px;
    right: 32px;
  }
}

@media all and (max-width: 780px) {
  .loader-page .loader img {
    margin-top: 0px;
    height: 40vh;
  }

  .socials.desktop {
    display: none;
  }

  aside .socials {
    margin-top: 56px;
  }

  .socials-menu.mobile {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 24px;
    position: absolute;
    bottom: 36px;
    left: 0;
    margin: 0 auto;
    width: 100%;
  }
}

.loader-page .loader img:hover {
  opacity: 1 !important;
}

.loader-page .close-loader {
  height: 0;
  -webkit-transform: scale(1.2) skewY(3deg);
          transform: scale(1.2) skewY(3deg);
}

.main-website-loading {
  position: absolute;
  -webkit-transition: all 0.8s cubic-bezier(0.72, 0.35, 0.57, 0.95);
  transition: all 0.8s cubic-bezier(0.72, 0.35, 0.57, 0.95);
}

.main-website-open {
  top: 0vh;
  -webkit-transform: skewY(0deg);
          transform: skewY(0deg);
}

.App {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.mobile-hamburgher {
  color: white;
  display: none;
}

@media all and (min-width: 781px) {
  .mobile-menu-close {
    display: none;
  }
}

@media all and (max-width: 780px) {
  .mobile-hamburgher {
    display: block;
    margin-bottom: 25px;
  }

  .mobile-menu-close {
    z-index: 9999999999;
    position: fixed;
    display: none;
  }

  body.menu-open .mobile-menu-close {
    display: block;
  }
}

.mobile-hamburgher .line {
  width: 35px;
  height: 2px;
  background-color: white;
  margin: 7px 0px;
}

aside {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 10px 30px;
  padding-right: 0px;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  z-index: 2;
  width: calc(100vw - 80px);
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

@media all and (max-width: 780px) {
  aside {
    padding: 10px;
    width: calc(100% - 40px);
  }
}

aside .menu-bb {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 100%;
  max-width: 1360px;
  margin-right: 32px;
  border-bottom: 1px solid #fff;
  padding-bottom: 30px;
}

aside .menu-bb .contact-menu a[href*="contact"]:hover > .item,
#root.contact aside .menu-bb .contact-menu a[href*="contact"] > .item,
#root.directors aside .menu-bb .items a[href*="directors"],
#root.narrative aside .menu-bb .items a[href*="narrative"] > .item,
aside .menu-bb .items .item.active {
  color: #000;
}

#root.contact aside .menu-bb .contact-menu a[href*="contact"] > .item,
#root.directors aside .menu-bb .items a[href*="directors"] > .item,
#root.narrative aside .menu-bb .items a[href*="narrative"] > .item {
  background: #FFF;
  opacity: 1;
}

@media all and (max-width: 780px) {
  aside .menu-bb {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100vw;
    z-index: 99999999;
    background-color: #000;
    display: none;
  }
}

aside .menu-bb video {
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  overflow: hidden;
  opacity: .5;
}

aside .contact-menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  width: 100%;
}

@media all and (max-width: 780px) {
  aside .contact-menu {
    display: block;
    width: 80%;
    margin: 0px 10%;
  }
}

aside .contact-menu .item {
  margin: 0px;
  padding: 3px 20px;
  border: 1px solid white;
  font-size: 14px;
  border-radius: 20px;
  opacity: .7;
}

@media all and (max-width: 780px) {
  aside .contact-menu .item {
    display: block;
    text-align: center;
    margin: 15px 0px;
    padding: 3px 20px;
  }
}

aside .contact-menu .item:hover {
  opacity: .9;
}

aside .contact-menu .item:hover {
  background-color: white;
}

aside .contact-menu .item:hover a {
  color: black;
}

aside .contact-menu a {
  color: white;
  text-decoration: none;
  font-weight: bold;
}

aside .contact-menu p {
  color: white;
  margin: 0px;
}

aside .contact-menu .active {
  background-color: white;
}

aside .contact-menu .active a {
  color: black;
}

aside .items {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

@media all and (max-width: 780px) {
  aside .items {
    display: block;
    width: 80%;
    margin: 160px 10% 0px;
  }
}

aside .items .item,
aside .single-director-name {
  padding: 3px 20px;
  border: 1px solid white;
  font-size: 14px;
  border-radius: 20px;
  margin: 0px;
  margin-right: 20px;
  opacity: .7;
  word-break: keep-all;
  white-space: nowrap;
}

@media all and (max-width: 780px) {
  aside .items .item {
    display: block;
    text-align: center;
    margin: 15px 0px;
    padding: 3px 20px;
  }
}

aside .items .item:hover {
  opacity: .9;
}

aside .items .item:hover {
  background-color: white;
}

aside .items a {
  color: white;
  text-decoration: none;
  font-weight: bold;
}

aside .items a:hover {
  color: black;
}

aside .items .active {
  background-color: white;
  opacity: 1;
}

aside .items .active a {
  color: black;
}

aside p {
  margin: 0px;
  opacity: .7;
}

aside p:hover {
  opacity: 1;
}

aside img {
  width: 150px;
}

aside .logo {
  opacity: .7;
}

aside .logo:hover {
  opacity: 1;
}

@media all and (max-width: 780px) {
  aside .logo {
    position: absolute;
    z-index: 9999999999;
    top: 10px;
  }
}

@media all and (max-width: 780px) {
  aside .content {
    display: none;
  }
}

aside .content span {
  padding: 0px 10px;
  font-weight: 900;
  font-size: 14px;
  display: block;
  color: white;
  -webkit-transform: translateY(-25px) rotate(90deg);
          transform: translateY(-25px) rotate(90deg);
  border-right: 2px solid #ccc;
}

aside .socials a {
  color: white;
  text-decoration: none;
  position: relative;
  z-index: 999999999999999999999999999999999999999;
}

.menu-open-black {
  background-color: black;
}

@media all and (max-width: 780px) {
  .menu-open-black {
    background-color: transparent;
  }
}

.menu {
  position: fixed;
  top: 0;
  left: 110px;
  bottom: 0;
  background-color: #fff;
  z-index: 9;
  background-color: black;
  -webkit-transition: all .8s ease;
  transition: all .8s ease;
  text-transform: uppercase;
}

@media all and (max-width: 780px) {
  #cursor {
    display: none !important;
  }

  .menu {
    left: 0px;
    min-height: 100vh;
    right: 0;
    width: 100%;
  }
}

.menu .items {
  display: none;
  padding: 50px 80px;
}

@media all and (max-width: 780px) {
  .menu .items {
    padding: 50px 40px;
  }
}

.menu .items .item {
  overflow: hidden;
  height: auto;
}

.menu .items .item a {
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%);
  color: white;
  font-size: 50px;
  font-weight: 500;
  text-decoration: none;
  display: inline-block;
  opacity: .8;
  position: relative;
  -webkit-transition: all .3s ease-out;
  transition: all .3s ease-out;
}

@media all and (max-width: 780px) {
  .menu .items .item a {
    font-size: 40px;
  }
}

.menu .items .item a::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0%;
  height: 2px;
  background-color: #fff;
  -webkit-transition: all .3s ease-out;
  transition: all .3s ease-out;
}

.menu .items .item a:hover {
  opacity: 1;
}

.menu .items .item a:hover::after {
  width: 100%;
}

.menu .others {
  position: absolute;
}

.menu .others .item {
  color: white;
}

.menu-closed {
  width: 0px;
}

.menu-open {
  width: calc(100% - 110px);
}

@media all and (max-width: 780px) {
  .menu-open {
    width: 100%;
  }
}

.slider {
  display: block;
  width: 100vw;
  height: 100vh;
  overflow: hidden scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.slider::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

.slider .previous {
  position: fixed;
  left: 30px;
  top: 0;
  bottom: 0;
  display: none;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  z-index: 9;
}

@media all and (max-width: 780px) {
  .slider .previous {
    display: none;
  }
}

.slider .previous img {
  opacity: .8;
  -webkit-filter: invert(1);
          filter: invert(1);
  height: 50px;
  cursor: pointer;
}

.mobile-thumbnails {
  display: none;
}

@media all and (max-width: 780px) {
  .slider .previous img {
    height: 40px;
  }

  .thumbnails:not(.mobile-thumbnails) {
    display: none;
  }

  .mobile-thumbnails {
    display: block;
    width: 100vw;
    height: 100vh;
    overflow: hidden scroll;
  }
}

.thumbnails .next,
.thumbnails .previous,
.mobile-thumbnails .next,
.mobile-thumbnails .previous,
.slider .next {
  position: fixed;
  right: 30px;
  top: calc(50% - 20px);
  bottom: 0;
display: none;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  z-index: 2;
  height: 40px;
}

@media all and (max-width: 780px) {
  .slider .next {
    right: 10px;
    display: none;
  }
}

.slider .next img {
  opacity: .8;
  -webkit-filter: invert(1);
          filter: invert(1);
  height: 50px;
  cursor: pointer;
}

@media all and (max-width: 780px) {
  .slider .next img {
    height: 40px;
  }
}

.mobile-thumbnails .slide,
.slider .slide {
  position: relative;
  overflow: hidden;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 100%;
          flex: 1 1 100%;
  width: 100vw !important;
  min-height: 100vh;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background-size: cover !important;
  background-position: center center !important;
  position: relative;
}

@media all and (max-width: 780px) {
  .mobile-thumbnails .slide,
  .slider .slide {
    z-index: 1;
  }
}

.mobile-thumbnails .slide .videoTag,
.slider .slide .videoTag {
  position: absolute;
  bottom: 0;
  top: 0;
  z-index: -1;
}

@media all and (max-width: 780px) {
  .mobile-thumbnails .slide,
  .slider .slide {
    min-height: 100vh;
    min-width: 375px;
  }
}

.mobile-thumbnails .slide a,
.slider .slide a {
  text-decoration: none;
}

.thumbnails .card a h1,
.mobile-thumbnails .slide a h1,
.slider .slide a h1 {
  opacity: .6;
  -webkit-transition: all .3s ease-out;
  transition: all .3s ease-out;
  padding: 0px 12px;
}

.slider .slide a p {
  opacity: .6 !important;
  -webkit-transition: all .3s ease-out;
  transition: all .3s ease-out;
}

.thumbnails .card a:hover h1,
.mobile-thumbnails .slide a:hover h1,
.slider .slide a:hover h1 {
  opacity: 1 !important;
}

.slider .slide a:hover p {
  opacity: 1 !important;
}

.mobile-thumbnails .slide::before,
.slider .slide::before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: #000;
  opacity: .3;
  z-index: 1;
}

.thumbnails .card a {
  text-decoration-line: none;
}

.thumbnails .card h1 {
  overflow: hidden;
  height: auto;
  color: white;
  font-size: 80px;
  font-weight: 700;
  margin: 0px;
  position: relative;
  z-index: 2;
  opacity: 1;
  text-align: center;
  font-family: 'LIQUIDO-Fluid';
}

.thumbnails .card h1:hover,
.slider .slide h1:hover {
  font-family: 'LIQUIDO-Reguar';
}

.mobile-thumbnails .slide h1,
.slider .slide h1 {
  overflow: hidden;
  height: auto;
  color: white;
  font-size: 200px;
  font-weight: 700;
  margin: 0px;
  position: relative;
  z-index: 2;
  opacity: 1;
  text-align: center;
  font-family: 'LIQUIDO-Fluid';
}

.mobile-thumbnails .slide h1:hover,
.slider .slide h1:hover {
  font-family: 'LIQUIDO-Reguar';
}

@media all and (max-width: 940px) {
  .contact.page .contact-details {
    display: block !important;
  }
}

@media all and (max-width: 780px) {
  .mobile-thumbnails .slide h1,
  .slider .slide h1 {
    font-size: 40px;
    line-height: 50px;
    height: auto;
  }
}

.mobile-thumbnails .slide h1 span,
.slider .slide h1 span {
  display: block;
}

.thumbnails .card p,
.mobile-thumbnails .slide p,
.slider .slide p {
  text-align: center;
  height: auto;
  opacity: 1;
  margin: 0px;
  color: white;
  position: relative;
  z-index: 2;
  font-size: 18px;
  overflow: hidden;
  font-weight: 300;
}

.mobile-thumbnails .slide p {
  font-size: 16px;
}

.works {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-left: 110px;
}

@media all and (max-width: 780px) {
  .works {
    padding-left: 0;
    display: block;
    max-width: 100vw;
  }
}

.works .page-title {
  padding: 50px 80px;
  width: 30vw;
  padding-top: 100px;
}

@media all and (max-width: 780px) {
  .works .page-title {
    width: 80%;
    min-height: 30vh;
    padding: 50px 50px;
  }
}

.works .page-title h1 {
  color: white;
  font-size: 100px;
  margin: 0px;
  font-weight: 600;
  text-transform: uppercase;
}

@media all and (max-width: 780px) {
  .works .page-title h1 {
    font-size: 60px;
  }
}

.works .page-title p {
  margin: 0px;
  color: white;
  font-size: 30px;
  text-transform: uppercase;
  font-weight: 600;
  cursor: none;
}

.works .page-title .filters p {
  overflow: hidden;
  height: auto;
  margin: 20px 0px;
  color: white;
  font-size: 26px;
  text-transform: uppercase;
  font-weight: 400;
  cursor: none;
}

.works .page-title .filters p span {
  display: inline-block;
  -webkit-transition: all .3s ease-out;
  transition: all .3s ease-out;
  position: relative;
}

.works .page-title .filters p span::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0%;
  height: 2px;
  background-color: #fff;
  -webkit-transition: all .3s ease-out;
  transition: all .3s ease-out;
}

.works .page-title .filters p span:hover {
  opacity: 1;
}

.works .page-title .filters p span:hover::after {
  width: 100%;
}

.works .cards {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

@media all and (max-width: 780px) {
  .works .cards {
    display: block;
  }
}

.works .card {
  cursor: none;
  background-size: cover;
  background-position: center center;
  height: 100vh;
  padding: 10px;
  width: 320px;
  position: relative;
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
}

@media all and (max-width: 780px) {
  .works .card {
    width: 104vw;
    height: 70vh;
  }
}

.works .card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000;
  opacity: .2;
  z-index: 1;
}

.works .card:hover {
  width: 550px;
}

.contactWave {
  width: 100vw;
  height: 100%;
}

@media all and (max-width: 780px) {
  .works .card:hover {
    width: 100vw;
  }
}

.works .card .content {
  position: absolute;
  bottom: 20px;
  width: 320px;
  padding: 0px 15px;
  overflow: hidden;
}

.works .card h3 {
  position: relative;
  z-index: 4;
  color: white;
  font-size: 36px;
  line-height: 60px;
  text-transform: uppercase;
  margin: 0px;
  font-weight: 600;
  max-width: 350px;
  white-space: normal !important;
}

@media all and (max-width: 780px) {
  .works .card h3 {
    max-width: 100vw;
  }
}

.works .card p {
  font-size: 24px;
  color: white;
  margin: 0px;
  text-transform: uppercase;
  width: 400px;
  white-space: normal !important;
}

.workItem {
  overflow: hidden;
  position: relative;
}

.workItem .workVideo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: white;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 90vh;
  -webkit-transition: all 1s ease-out;
  transition: all 1s ease-out;
}

@media all and (max-width: 780px) {
  .workItem .workVideo {
    display: block;
  }
}

.workItem .workVideo .video {
  min-width: 60vw;
  height: 50vh;
  position: relative;
}

@media all and (max-width: 780px) {
  .workItem .workVideo .video {
    width: 100%;
    height: 60vh;
  }
}

.workItem .workVideo .video .videoPlayer {
  position: relative;
}

.workItem .workVideo .video .videoPlayer::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
  z-index: 8;
}

.workItem .workVideo .video .credits {
  position: absolute;
  bottom: 80px;
  left: 21%;
  right: 0;
  z-index: 999;
}

.workItem .workVideo .video .credits .credits-title-style {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 16px;
  margin-bottom: 5px;
  font-weight: 700;
  opacity: .6;
  cursor: pointer;
  -webkit-transition: all .3s ease-out;
  transition: all .3s ease-out;
}

.workItem .workVideo .video .credits .credits-title-style span {
  margin: 0px 15px;
}

.workItem .workVideo .video .credits .credits-title-style p {
  text-transform: uppercase;
  margin: 0;
  font-size: 16px;
  line-height: auto;
  line-height: 18px !important;
  font-weight: 700;
  position: relative;
}

.workItem .workVideo .video .credits .credits-title-style p::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0%;
  height: 2px;
  background-color: #fff;
  -webkit-transition: all .3s ease-out;
  transition: all .3s ease-out;
}

.workItem .workVideo .video .credits .credits-title-style p:hover {
  opacity: 1;
}

.workItem .workVideo .video .credits .credits-title-style p:hover::after {
  width: 100%;
}

.workItem .workVideo .video .credits .content {
  font-size: 16px;
  display: none;
  overflow: hidden;
  -webkit-transition: all .3s ease-out;
  transition: all .3s ease-out;
  text-transform: uppercase;
  white-space: pre-wrap !important;
}

.workItem .workVideo .video .credits .content p {
  margin: 0;
  font-size: 16px;
  line-height: 18px !important;
  opacity: .6;
  white-space: pre-wrap !important;
}

.workItem .workVideo .video .credits .visible {
  height: auto;
  -webkit-transition: all .3s ease-out;
  transition: all .3s ease-out;
}

.workItem .workVideo .video .credits .title-visible {
  opacity: 1;
}

.workItem .workVideo .video .video-controllers {
  opacity: 0;
  bottom: 20px;
  width: 60%;
  padding: 10px 10px;
  border-radius: 2px;
  left: 20%;
  position: absolute;
  z-index: 9;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background: rgba(0, 0, 0, 0.226);
  backdrop-filter: blur(13px);
  -webkit-backdrop-filter: blur(13px);
  border-radius: 10px;
  -webkit-transition: all .3s ease-out;
  transition: all .3s ease-out;
}

@media all and (max-width: 780px) {
  .workItem .workVideo .video .video-controllers {
    opacity: .5;
    z-index: 9999;
    width: 90%;
    margin: 0px 3%;
    left: 0;
    bottom: 100px;
    padding: 5px;
  }
}

.workItem .workVideo .video .video-controllers .play {
  width: 10%;
  margin: 0px;
  text-align: center;
}

.workItem .workVideo .video .video-controllers .volume {
  width: 10%;
  margin: 0px;
}

.workItem .workVideo .video .video-controllers .timestamp {
  width: 10%;
  font-size: 10px;
  margin: 0px;
}

.workItem .workVideo .video .video-controllers .fullscreen {
  width: 10%;
  margin: 0px;
  text-align: center;
}

.workItem .workVideo .video .video-controllers .video-range {
  width: 65%;
}

.workItem .workVideo .video .video-controllers .video-range input {
  display: block;
  width: 100%;
  -webkit-appearance: none;
  vertical-align: middle;
  outline: none;
  border: none;
  padding: 0;
  background: none;
  opacity: .8;
}

.workItem .workVideo .video .video-controllers .video-range input::-webkit-slider-runnable-track {
  background-color: #606670;
  border-radius: 10px;
  height: 1px;
}

.workItem .workVideo .video .video-controllers .video-range input::-moz-range-track {
  background-color: #606670;
  border: none;
}

.workItem .workVideo .video .video-controllers .video-range input::-ms-fill-lower {
  background-color: #606670;
}

.workItem .workVideo .video .video-controllers .video-range input::-ms-fill-upper {
  background-color: #606670;
}

.workItem .workVideo .video .video-controllers .video-range input::-webkit-slider-thumb {
  background-color: #aaa;
  -webkit-transform: translate(0%, -50%);
          transform: translate(0%, -50%);
}

.workItem .workVideo .video .video-controllers .video-range input::-moz-range-thumb {
  background-color: #aaa;
}

.workItem .workVideo .video .video-controllers .video-range input::-ms-thumb {
  background-color: #aaa;
}

.workItem .workVideo .video:hover .video-controllers {
  opacity: 1;
}

.workItem .workVideo .content {
  width: 30%;
}

@media all and (max-width: 780px) {
  .workItem .workVideo .content {
    width: 100%;
    padding: 10px;
  }
}

.workItem .workVideo p {
  white-space: normal;
  font-size: 20px;
  font-weight: 100;
  opacity: .8;
  line-height: 25px;
}

@media all and (max-width: 780px) {
  .workItem .workVideo p {
    font-size: 14px;
  }
}

.workItem .fullscreenVideo {
  display: block;
  width: 100vw;
  height: 100vh;
}

.workItem .fullscreenVideo .video {
  width: 100%;
  height: 100%;
}

.workItem .fullscreenVideo .video .video-controllers {
  bottom: 30px;
}

.workItem .title {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  height: 18vh;
  text-transform: uppercase;
  font-size: 7rem;
  margin: 0px;
  color: white;
}

@media all and (max-width: 780px) {
  .workItem .title {
    position: relative;
    margin-top: 50px;
    margin-bottom: 50px;
    font-size: 3rem;
  }
}

.directors:not(#root) {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background-position: center center;
  background-size: cover;
  min-width: 100vw;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  overflow-x: hidden;
  overflow-y: hidden;
}

@media all and (max-width: 780px) {
  .directors:not(#root) {
    display: block;
    padding-left: 0px;
    max-width: 100vw;
  }
}

.directors .page-title {
  padding: 50px 80px;
  min-width: 30vw;
  padding-top: 100px;
}

@media all and (max-width: 780px) {
  .directors .page-title {
    padding: 50px 30px;
  }
}

.directors .page-title h1 {
  color: white;
  font-size: 100px;
  margin: 0px;
  font-weight: 500;
  text-transform: uppercase;
}

@media all and (max-width: 780px) {
  .directors .page-title h1 {
    font-size: 50px;
  }
}

.directors .directors-name {
  padding-bottom: 100px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.directors .directors-name .name {
  width: 100% !important;
  margin: 5px 0px;
  color: white;
  font-size: 45px;
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
  opacity: .8;
  cursor: none;
}

.directors .directors-name .name:hover {
  opacity: 1;
}

.directors .directors-name .name a {
  color: white;
  text-decoration: none;
  font-family: LIQUIDO-Reguar;
}

.directors .directors-name .name a:hover {
  font-family: LIQUIDO-Fluid;
}

@media all and (min-width: 781px) and (min-width: 1280px) {
  .directors .directors-name .name {
    font-size: 80px !important;
    margin: 3px 0px !important;
  }
}


@media all and (min-width: 781px) and (max-width: 1279px) {
  .directors .directors-name .name {
    font-size: 60px !important;
    margin: 3px 0px !important;
  }
}

@media all and (max-width: 780px) {
  .directors .directors-name .name {
    font-size: 50px !important;
    margin: 8px 0px !important;
  }
}

.directors .directors-name .name span {
  -webkit-transition: all .3s ease-out;
  transition: all .3s ease-out;
  position: relative;
}

.directors .directors-name .name span::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0%;
  height: 2px;
  background-color: #fff;
  -webkit-transition: all .3s ease-out;
  transition: all .3s ease-out;
}

.directors .directors-name .name span:hover {
  opacity: 1;
}

.directors .directors-name .name span:hover::after {
  width: 100%;
}

.directors .director-bio {
  width: 30vw;
  color: white;
  display: none;
}

.directors .director-bio p {
  white-space: normal;
}

.directors .cards {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

@media all and (max-width: 780px) {
  .directors .cards {
    display: block;
  }
}

.directors .card {
  cursor: none;
  background-image: url(../images/619cb1fa0ade2242152d104a_proud-to-be-freak.jpeg);
  background-size: cover;
  background-position: center;
  height: 100vh;
  padding: 10px;
  width: 320px;
  position: relative;
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
}

@media all and (max-width: 780px) {
  .directors .card {
    width: 100vw;
    height: 70vh;
  }
}

.directors .card:nth-child(2) {
  background-image: url(../images/6193ad66b0f40725f4e47b8e_contact-big.jpg);
}

.directors .card:nth-child(3) {
  background-image: url(../images/619cb17585a3c9679ed6605b_start-the-buzz.jpeg);
}

.directors .card:nth-child(4) {
  background-image: url(../images/619cb1debb2a7f6142dc00e0_woolmark.jpeg);
}

.directors .card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000;
  opacity: .2;
  z-index: 1;
}

.directors .card:hover {
  width: 550px;
}

@media all and (max-width: 780px) {
  .directors .card:hover {
    width: 100vw;
  }
}

.directors .card .content {
  position: absolute;
  bottom: 60px;
  width: 400px;
  overflow: hidden;
  padding: 0px 20px;
}

@media all and (max-width: 780px) {
  .directors .card .content {
    width: 100vw;
    bottom: 20px;
  }
}

.directors .card h3 {
  position: relative;
  z-index: 4;
  color: white;
  font-size: 50px;
  line-height: 40px;
  text-transform: uppercase;
  margin: 0px;
  font-weight: 700;
  max-width: 350px;
  white-space: normal !important;
}

@media all and (max-width: 780px) {
  .directors .card h3 {
    font-size: 40px;
    line-height: 40px;
  }
}

.directors .card p {
  font-size: 24px;
  color: white;
  margin: 0px;
  text-transform: uppercase;
  width: 400px;
  white-space: normal !important;
}

.contactDesc {
  white-space: pre-line;
}

.contact.page {
  /* padding-left: 180px; */
  /* min-height: 100vh; */
  height: calc(100vh - 164px);
  /* padding-top: 22vh; */
  background-position: center center;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  margin-top: 164px;
  overflow: auto;
}

.contactWave {
  width: 100vw;
  height: 100%;
  overflow: hidden;
}

@media all and (max-width: 780px) {
  .contact.page {
    display: block;
    /* min-height: 130vh; */
    padding-left: 0;
    /* overflow: scroll !important; */
  }
}

.contact.page .page-title {
  padding: 50px 80px;
  width: 30vw;
}

@media all and (max-width: 780px) {
  .contact.page .page-title {
    width: 100%;
    padding: 50px 10px;
  }
}

.contact.page .page-title h1 {
  color: #fff;
  font-size: 100px;
  margin: 0px;
  font-weight: 600;
  text-transform: uppercase;
}

@media all and (max-width: 780px) {
  .contact.page .page-title h1 {
    font-size: 20px;
  }
}

.contact.page .country:not(.locationContact):not(.long-text) {
  margin: 0 4% 0 8%;
}

.contact.page .country.long-text {
  margin: 0 4% 0 8%;
}

.contact.page .country.long-text .contactDesc {
  padding-right: 16px;
}

.contact.page .country.locationContact {
  margin: 0;
  margin-left: 5%;
  margin-right: 5%;
  padding-bottom: 216px;
  width: 100%;
  max-width: calc(100% - 80px);
}

@media all and (max-width: 1280px) {
  .directors .directors-name .name {
    font-size: 32px;
  }
}

@media all and (max-width: 1548px) {
  .contact.page .country.locationContact {
    width: initial;
  }
}

@media all and (min-width: 781px) and (max-width: 900px) {
  aside .menu-bb .items .item {
    padding: 3px 9px 1px;
    margin-right: 10px;
  }
}

@media all and (max-width: 780px) {
  .contact.page .country:not(.locationContact),
  .contact.page .country {
    display: block;
    margin-left: 5vw;
    margin-right: 5vw;
  }

  .contact.page .country.locationContact {
    margin-left: 5%;
    margin-right: 5%;
  }
}

.contact.page .country h5 {
  font-weight: 600;
  font-size: 18px;
  color: #fafafa;
  margin: 0px;
  /* width: 70vw; */
  padding-right: 0;
  white-space: pre-line !important;
  opacity: .8;
  -webkit-transition: all .1s ease-out;
  transition: all .1s ease-out;
  margin-bottom: 30px;
}

.contact.page .country h5:hover {
  opacity: 1;
}

@media all and (max-width: 940px) {
  .contact.page .country h5 {
    padding-right: 0;
    /* width: 90vw; */
    font-size: 16px;
  }

  .contact.page .country .infos {
    display: block !important;
  }

  .contact.page .country:not(.locationContact):not(.long-text) {
    margin: 0 5%;
  }

  .contact.page .country .contat-info {
    margin-right: 0 !important;
  }

  }

.contact.page .country h1 {
  text-transform: uppercase;
  color: #fff;
  font-size: 100px;
  margin: 0px 0px 20px;
  line-height: 100px;
  opacity: .8;
  font-weight: 800;
  -webkit-transition: all .1s ease-out;
  transition: all .1s ease-out;
  display: inline-block;
  font-family: 'LIQUIDO-Fluid';
}

.contact.page .country h1:hover {
  font-family: 'LIQUIDO-Reguar';
}

.contact.page .country h1:hover {
  opacity: 1;
}

@media all and (max-width: 780px) {
  .contact.page .country h1 {
    margin: 0px 0px 50px;
    font-size: 70px;
    line-height: 60px;
  }
}

.contact.page .country .infos {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

@media all and (max-width: 780px) {
  .contact.page .country .infos {
    display: block;
  }
}

.contact.page .country .contat-info {
  margin: 20px 0px;
  margin-right: 40px;
}

.contact.page .country .contat-info h4 {
  font-size: 26px;
  color: #fff;
  margin: 0px;
  opacity: .8;
  -webkit-transition: all .1s ease-out;
  transition: all .1s ease-out;
}

.contact.page .country .contat-info h4:hover {
  opacity: 1;
}

.contact.page .country .contat-info p {
  color: #fff;
  font-weight: 300;
  opacity: .8;
  -webkit-transition: all .1s ease-out;
  transition: all .1s ease-out;
}

.contact.page .country .contat-info p > a {
  color: #fff;
}

.contact.page .country .contat-info p:hover {
  opacity: 1;
}

.contact.page .contact-details {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
}

@media all and (max-width: 780px) {
  .contact.page .contact-details {
    display: block;
  }
}

.scroll-status {
  position: fixed;
  bottom: 50px;
  left: 0;
  width: 100vw;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  z-index: 999;
}

.scroll-status #scrollRange {
  border: 1px solid white;
  background-color: transparent;
  height: 20px;
  width: 220px;
  border-radius: 50px;
  opacity: .6;
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
  outline: none;
  -webkit-appearance: none;
}

.scroll-status #scrollRange:hover {
  opacity: 1;
}

.scroll-status #scrollRange::-webkit-slider-thumb {
  height: 17px;
  width: 17px;
  cursor: pointer;
  background-color: #fff;
  border-radius: 50px;
  -webkit-appearance: none;
}

.row {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr)[12];
      grid-template-columns: repeat(12, 1fr);
  gap: 10px;
}

.row .col-md-6 {
  margin: 0px 15px;
  grid-column: span 6;
}

@media all and (max-width: 780px) {
  .row .col-md-6 {
    grid-column: span 12;
  }
}

.construction {
  position: fixed;
  background-image: url(../images/eb83d5d801f16eab225660ddd44d22d4.png);
  background-size: cover;
  background-position: center center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999999999999999999999999999999;
}

@media all and (max-width: 780px) {
  .construction {
    overflow: scroll;
  }
}

.construction .container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.construction .container .content {
  text-align: center;
}

.construction .container .content img {
  height: 40vh;
}

@media all and (max-width: 780px) {
  .construction .container .content img {
    height: 20vh;
    margin: 0px;
  }
}

.construction .container .content h3 {
  color: white;
  font-size: 28px;
}

.construction .container .country {
  margin: 20px 5vw;
}

@media all and (max-width: 734px) {
  .backToTop {
    right: 24px !important;
  }
}

@media all and (max-width: 780px) {
  .construction .container .country {
    margin: 0;
  }
}

@media all and (max-width: 780px) {
  .construction .container .country {
    display: block;
  }
}

.construction .container .country h5 {
  font-weight: 600;
  font-size: 30px;
  color: #fafafa;
  margin: 0px;
  width: 70vw;
  padding-right: 10%;
  white-space: normal !important;
  opacity: .8;
  -webkit-transition: all .1s ease-out;
  transition: all .1s ease-out;
  margin-bottom: 90px;
}

.construction .container .country h5:hover {
  opacity: 1;
}

.construction .container .country h6 {
  font-weight: 900;
  font-size: 28px;
  color: #fff;
  margin: 0px;
  opacity: .8;
  -webkit-transition: all .1s ease-out;
  transition: all .1s ease-out;
}

.construction .container .country h6:hover {
  opacity: 1;
}

.construction .container .country h1 {
  text-transform: uppercase;
  color: #fff;
  font-size: 100px;
  margin: 0px 0px 20px;
  line-height: 100px;
  opacity: .8;
  font-weight: 800;
  -webkit-transition: all .1s ease-out;
  transition: all .1s ease-out;
  display: inline-block;
  font-family: 'LIQUIDO-Fluid';
}

.construction .container .country h1:hover {
  font-family: 'LIQUIDO-Reguar';
}

.construction .container .country h1:hover {
  opacity: 1;
}

@media all and (max-width: 780px) {
  .construction .container .country h1 {
    margin: 0px 0px 0px;
    font-size: 70px;
    line-height: 60px;
  }
}

.construction .container .country .infos {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

@media all and (max-width: 780px) {
  .construction .container .country .infos {
    display: block;
  }
}

.construction .container .country .contat-info {
  margin: 20px 0px;
  margin-right: 40px;
}

@media all and (max-width: 780px) {
  .construction .container .country .contat-info {
    margin-right: 0;
  }
}

.construction .container .country .contat-info h4 {
  font-size: 26px;
  color: #fff;
  margin: 0px;
  opacity: .8;
  -webkit-transition: all .1s ease-out;
  transition: all .1s ease-out;
}

.construction .container .country .contat-info h4:hover {
  opacity: 1;
}

.construction .container .country .contat-info p {
  color: #fff;
  font-weight: 300;
  opacity: .8;
  -webkit-transition: all .1s ease-out;
  transition: all .1s ease-out;
}

.construction .container .country .contat-info p:hover {
  opacity: 1;
}

.construction .container .contact-details {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

@media all and (max-width: 780px) {
  .construction .container .contact-details {
    display: block;
  }
}

.back {
  -webkit-filter: invert(100);
          filter: invert(100);
  position: fixed;
  display: block;
  opacity: .7;
  right: 70px;
  top: 50px;
  height: 35px;
  z-index: 9999 !important;
  -webkit-transition: all .3s ease-out;
  transition: all .3s ease-out;
}

.back:hover {
  opacity: 1;
}

@media all and (max-width: 780px) {
  .back {
    right: 30px;
    top: 60px;
  }
}

.back img {
  height: 100%;
}

.locationContact {
  border-top: 2px solid #fff;
  opacity: .7;
  padding-top: 10px;
  margin-top: 20px;
}

.locationContact a {
  color: white;
  text-decoration: none;
}

body.menu-open .backToTop {
  display: none;
}

body.menu-open a:has(.logo) {
  pointer-events: none;
}

.backToTop {
  position: fixed;
  bottom: 24px;
  right: 47px;
  z-index: /* 9999999; */ 9;
  background: rgba(0, 0, 0, .5);
  border-radius: 100px;
  width: 36px;
  height: 36px;
  justify-content: center;
  align-items: center;
  display: none;
}

.backToTop img {
  -webkit-filter: invert(100);
          filter: invert(100);
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  width: 24px;
  margin: 6px;
}

@media screen and (orientation: landscape) and (max-width: 926px) {
  .mobile-thumbnails .slide h1,
  .slider .slide h1 {
    margin-top: 0;
    font-size: 76px;
    line-height: 86px;
  }

  aside {
    padding: 5px 30px;
  }

  aside img {
    width: 125px;
  }

  aside .items .item,
  aside .contact-menu .item {
    padding: 3px 14px;
  }

  aside .contact-menu {
    justify-content: flex-start;
    align-items: flex-start;
  }

  .directors .directors-name {
    padding-top: 25px;
    padding-bottom: 75px;
  }

  .directors .directors-name .name {
    font-size: 50px !important;
    line-height: 56px;
  }

  .slide > video {
    object-fit: cover;
    object-position: center;
    height: 100vh;
  }
}

@media screen and (max-width: 767px) {
  .contact.page .country.locationContact {
    max-width: 100%;
  }
}

@media screen and (orientation: landscape) and (max-width: 667px) {
  .mobile-thumbnails .slide h1,
  .slider .slide h1 {
    margin-top: 40px;
  }
}

