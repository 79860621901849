@font-face {
    font-family: 'Archivo-Bold';
    font-style: normal;
    font-weight: normal;
    src: url('../fonts/Archivo-Bold.ttf') format('truetype');
  }
  @font-face {
    font-family: 'Archivo-Regular';
    font-style: normal;
    font-weight: normal;
    src: url('../fonts/Archivo-Regular.ttf') format('truetype');
  }

  @font-face {
    font-family: 'LIQUIDO-Reguar';
    src: url('../fonts/LIQUIDO-Reguar.otf') format('truetype');
  }
  @font-face {
    font-family: 'LIQUIDO-Fluid';
    src: url('../fonts/LIQUIDO-Fluid.otf') format('truetype');
  }


body{
    margin: 0px;
    font-family: 'Archivo-Regular';
    padding: 0px;
    background-color: #000;
    overflow-y: hidden !important;
    scrollbar-gutter: stable both-edges;
    overflow: hidden;
    @media all and (max-width: 780px) {
        overflow-y: scroll !important;
    }
    
}

.main-website{
    // overflow-y: hidden;
    // overflow-x: scroll;
}


/* width */
::-webkit-scrollbar {
    width: 0px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent; 
    opacity: 0;
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: transparent; 
    opacity: 0;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555; 
    background: transparent; 
  }

#cursor {
	position: absolute;
    z-index: 99999;
    background-color: rgba(255, 255, 255, 1);
	width: 20px; 
	height: 20px; 
    border-radius: 50%;
    cursor: pointer;
    pointer-events: none;
    mix-blend-mode: exclusion;
    transition: transform 0.3s ease;
}

.loader-page{
    // display: none;
    z-index: 9999999999;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 100vh;
    overflow: hidden;
    .glitch{
        filter: invert(1);
    }
    .socials{
        display: flex;
        align-items: center;
        justify-content: center;
        a{
            margin: 0px 10px;
            opacity: .8;
        }
        img{
            height: 40px !important;
        }
    }
    &::before{
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: #000;
        z-index: -2;
    }
    &::after{
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: -1;
        background:
        linear-gradient(to right, black, rgba(0, 0, 0, .3)),
        url(https://grainy-gradients.vercel.app/noise.svg);
        opacity: .5;
    }
    .videoTag{
        position: absolute;
        top: 0;
        bottom: 0;
        z-index: -1;
        height: 100vh;
        width: 100vw;
        opacity: .8;
        background-color: #000;
    }
    .loader{
        background-position: center center;
        background-size: cover;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100vh;
        overflow: hidden;
        transition: all .8s cubic-bezier(.72,.35,.57,.95);
        transform: scale(1.2) skewY(0deg);
        img{
            height: 70vh;
            opacity: 0;
            margin-top: 200px;
            transition: all .3s ease-out;
            cursor: pointer;
            @media all and (max-width: 780px){
                margin-top: 0px;
                height: 40vh;
            }
            &:hover{
                opacity: 1 !important;
            }
        }
    }
    .close-loader{
        height: 0;
        transform:  scale(1.2) skewY(3deg);
    }
}
.main-website-loading{
    position: absolute;
    transition: all .8s cubic-bezier(.72,.35,.57,.95);
}
.main-website-open{
    top: 0vh;
    transform: skewY(0deg);
}
// cursor{
//     position: fixed;
//     width: 40px;
//     height: 40px;
//     margin-left: -20px;
//     margin-top: -20px;
//     border-radius: 2px solid #16a085;
//   }

// SideBar

.App{
    box-sizing: border-box;
}



.mobile-hamburgher{
    color: white;
    display: none;
    @media all and (max-width: 780px) {
        display: block;   
        margin-bottom: 25px;
    }
    .line{
        width: 35px;
        height: 2px;
        background-color: white;
        margin: 7px 0px;
    }
}


aside{
    display: flex;
    padding: 10px 30px;
    padding-right: 0px;
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    z-index: 2;
    width: calc(100vw - 80px);
    transition: all .4s ease-in-out;
    align-items: center;
    justify-content: space-between;
    @media all and (max-width: 780px) {
        padding: 10px;
        width: calc(100% - 40px);
    }
    .menu-bb{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 73vw;
        border-bottom: 1px solid #fff;
        padding-bottom: 30px;
        @media all and (max-width: 780px) {
            position: fixed;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            width: 100vw;
            z-index: 999;
            background-color: #000;
            display: none;
        }
        video{
            position: absolute;
            z-index: -1;
            top: 0;
            bottom: 0;
            overflow: hidden;
            opacity: .5;
        }
    }
    .contact-menu{
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        width: 100%;
        @media all and (max-width: 780px) {
            // display: none !important;
            display: block;
            width: 80%;
            margin: 0px 10%;

        }
        .item{
            margin: 0px ;
            padding: 3px 20px 1px;
            border: 1px solid white;
            font-size: 14px;
            border-radius: 20px;
            opacity: .7;
            @media all and (max-width: 780px) {
                // display: none !important;
                display: block;
                text-align: center;
                margin: 15px 0px;
                padding: 5px 20px 3px;
                
                
            }
            &:hover{
                opacity: .9;
            }
            &:hover{
                background-color: white;
                a{
                    color: black;
                }
            }
        }
        a{
            color: white;
            text-decoration: none;
            font-weight: bold;
        }
        p{
            color: white;
            margin: 0px;
        }
        .active{
            background-color: white;
            a{
                color: black;
            }
        }
    }
    .items{
        display: flex;
        align-items: center;
        @media all and (max-width: 780px) {
            // display: none !important;
            display: block;
            width: 80%;
            margin: 160px 10% 0px;

        }
        
        .item{
            padding: 3px 20px 1px;
            border: 1px solid white;
            font-size: 14px;
            border-radius: 20px;
            margin: 0px;
            margin-right: 20px;
            opacity: .7;
            @media all and (max-width: 780px) {
                // display: none !important;
                display: block;
                text-align: center;
                margin: 15px 0px;
                padding: 5px 20px 3px;
                
                
            }
            &:hover{
                opacity: .9;
            }
            &:hover{
                background-color: white;
            }
        }
        a{
            color: white;
            text-decoration: none;
            font-weight: bold;
            &:hover{
                color: black;
            }
        }
        .active{
            background-color: white;
            opacity: 1;
            a{
                color: black;
            }
        }
    }
    p{
        margin: 0px;
        opacity: .7;
        &:hover{
            opacity: 1;
        }
    }
    img{
        width: 150px;        
    }
    .logo{
        opacity: .7;
        &:hover{
            opacity: 1;
        }
        @media all and (max-width: 780px) {
            position: absolute;
            z-index: 9999999999;
            top: 10px;
        }
    }
    .content{
        @media all and (max-width: 780px){
            display: none;
        }
        span{
            padding: 0px 10px;
            font-weight: 900;
            font-size: 14px;
            display: block;
            color: white;
            transform: translateY(-25px) rotate(90deg);
            border-right: 2px solid #ccc;
        }
    }    
    .socials{
        margin-top: 40px;
        @media all and (max-width: 780px) {
            margin-top: 0px
        }
        a{
            color: white;
            text-decoration: none;
            position: relative;
            z-index: 999999999999999999999999999999999999999;
        }
    }
}

.menu-open-black{
    background-color: black;
    @media all and (max-width: 780px){
        background-color: transparent;
    }
}

// Menu

.menu{
    // display: none;
    position: fixed;
    top: 0;
    left: 110px;
    bottom: 0;
    background-color: #fff;
    z-index: 9;
    background-color: black;
    transition: all .8s ease;
    text-transform: uppercase;
    @media all and (max-width: 780px){
        left: 0px;
        min-height: 100vh;
        right: 0;
        width: 100%;
    }
    .items{
        display: none;
        padding: 50px 80px;
        @media all and (max-width: 780px){
            padding: 50px 40px;
        }
        .item{
            overflow: hidden;
            height: auto;
            a{
                transform: translateY(-100%);
                color: white;
                font-size: 50px;
                font-weight: 500;
                text-decoration: none;
                display: inline-block;
                opacity: .8;
                position: relative;
                transition: all .3s ease-out;
                @media all and (max-width: 780px){
                    font-size: 40px;
                }
                &::after{
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 0%;
                    height: 2px;
                    background-color: #fff;
                    transition: all .3s ease-out;
                }
                &:hover{
                    opacity: 1;
                    &::after{
                        width: 100%;
                    }
                }
            }
        }
    }
    .others{
        position: absolute;
        .item{
            color: white;
        }
    }
}

.menu-closed{
    width: 0px;
}
.menu-open{
    width: calc(100% - 110px);
    @media all and (max-width: 780px) {
        width: 100%;
    }
}

// Slider
.slider{
    display: inline-flex;
    // width: 2500vw;
        @media all and (max-width: 780px) {
            display: block;
            width: 100vw;
            height: 100vh;
            overflow: scroll;
        }
        .previous{
            position: fixed;
            left: 30px;
            top: 0;
            bottom: 0;
            display: flex;
            align-items: center;
            z-index: 9;
            @media all and (max-width: 780px) {
                display: none;
            }
            img{
                opacity: .8;
                filter: invert(1);
                height: 50px;
                cursor: pointer;
                @media all and (max-width: 780px) {
                    height: 40px;
                }
            }
        }
        .next{
            position: fixed;
            right: 30px;
            top: calc(50% - 20px);
            bottom: 0;
            display: flex;
            align-items: center;
            z-index: 2;
            height: 40px;
            @media all and (max-width: 780px) {
                right: 10px;
                display: none;
            }
            img{
                opacity: .8;
                filter: invert(1);
                height: 50px;
                cursor: pointer;
                @media all and (max-width: 780px) {
                    height: 40px;
                }
            }
        }
    .slide{
        position: relative;
        overflow: hidden;
        @media all and (max-width: 780px){
            // overflow: scroll;
            // object-fit: cover;
            z-index: 1;
        }
        .videoTag{
            position: absolute;
            bottom: 0;
            top: 0;
            z-index: -1;
        }
        flex: 1 1 100%;
        width: 100vw !important;
        min-height: 100vh;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background-size: cover !important;
        background-position: center center !important;
        position: relative;
        @media all and (max-width: 780px) {
            min-height: 100vh;
            min-width: 375px; 
        }
        a{
            text-decoration: none;
        }
        a h1{
            opacity: .6;
            transition: all .3s ease-out;
            padding: 0px 12px;
        }
        a p{
            opacity: .6 !important;
            transition: all .3s ease-out;
        }
        a:hover h1{
            opacity: 1 !important;
        }
        a:hover p{
            opacity: 1 !important;
        }
        &::before{
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            background-color: #000;
            opacity: .3;
            z-index: 1;
        }
        h1{
            overflow: hidden;
            height: auto;
            color: white;
            font-size: 200px;
            font-weight: 700;
            margin: 0px;
            position: relative;
            z-index: 2;
            opacity: 1;
            text-align: center;
            font-family: 'LIQUIDO-Fluid';
            &:hover{
                font-family: 'LIQUIDO-Reguar';
            }
            @media all and (max-width: 780px) {
                font-size: 40px;
                line-height: 50px;
                height: auto;
            }
            span{
                display: block;
            }
        }
        p{
            text-align: center;
            height: auto;
            opacity: 1;
            margin: 0px;
            color: white;
            position: relative;
            z-index: 2;
            font-size: 24px;
            overflow: hidden;
            font-weight: 300;
        }
    }
}


// Works

.works{
    display: flex;
    padding-left: 110px;
    @media all and (max-width: 780px){
        padding-left: 0;
        display: block;
        max-width: 100vw;
    }
   .page-title{
       padding: 50px 80px;
       width: 30vw;
       padding-top: 100px;
       @media all and (max-width: 780px){
           width: 80%;
           min-height: 30vh;
           padding: 50px 50px;
       }
       h1{
           color: white;
           font-size: 100px;
           margin: 0px;
           font-weight: 600;
           text-transform: uppercase;
           @media all and (max-width: 780px){
               font-size: 60px;
           }
       }
       p{
           margin: 0px;
           color: white;
           font-size: 30px;
           text-transform: uppercase;
           font-weight: 600;
           cursor: none;
       }
       .filters{
        //    display: none;
           p{
            overflow: hidden;
            height: auto;
            margin: 20px 0px;
            color: white;
            font-size: 26px;
            text-transform: uppercase;
            font-weight: 400;
            cursor: none;
            span{
                display: inline-block;
                transition: all .3s ease-out;
                position: relative;
                &::after{
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 0%;
                    height: 2px;
                    background-color: #fff;
                    transition: all .3s ease-out;
                }
                &:hover{
                    opacity: 1;
                    &::after{
                        width: 100%;
                    }
                }
            }
           }
       }
   } 
   .cards{
       display: flex;
       @media all and (max-width: 780px){
           display: block;
       }
   }
   .card{
        cursor: none;
        background-size: cover;
        background-position: center center;
        height: 100vh;
        padding: 10px;
        width: 320px;
        position: relative;
        @media all and (max-width: 780px){
            width: 104vw;
            height: 70vh;
        }
        &::before{
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: #000;
            opacity: .2;
            z-index: 1;
        }
        transition: all .3s ease-in-out;
        &:hover{
            width: 550px;
            @media all and (max-width: 780px){
                width: 100vw;
            }
        }
        .content{
            position: absolute;
            bottom: 20px;
            width: 320px;
            padding: 0px 15px;
            overflow: hidden;
        }
        h3{
            position: relative;
            z-index: 4;
            color: white;
            font-size: 36px;
            line-height: 60px;
            text-transform: uppercase;
            margin: 0px;
            font-weight: 600;
            max-width: 350px;
            white-space: normal !important;
            @media all and (max-width: 780px){
                max-width: 100vw;
            }
        }
        p{
            font-size: 24px;
            color: white;
            margin: 0px;
            text-transform: uppercase;
            width: 400px;
            white-space: normal !important;
        }
    }
}

.workItem{
    overflow: hidden;
    .workVideo{
        display: flex;
        align-items: center;
        color: white;
        justify-content: center;
        height: 90vh;
        @media all and (max-width: 780px){
            display: block;
        }
        .video{
            min-width: 60vw;
            height: 50vh;
            position: relative;
            @media all and (max-width: 780px){
                width: 100%;
                height: 60vh;
            }
            .videoPlayer{
                position: relative;
                &::after{
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background-color: transparent;
                    z-index: 8;
                }
                .player{
                }
            }
            .credits{
                // display: none;
                position: absolute;
                bottom: 80px;
                left: 21%;
                right: 0;
                z-index: 999;
                .credits-title-style{
                    display: flex;
                    align-items: center;
                    font-size: 16px;
                    margin-bottom: 5px;
                    font-weight: 700;
                    opacity: .6;
                    cursor: pointer;
                    transition: all .3s ease-out;
                    span{
                        margin: 0px 15px;
                    }
                    p{
                        text-transform: uppercase;
                        margin: 0;
                        font-size: 16px;
                        line-height: auto;
                        line-height: 18px !important;
                        font-weight: 700;
                        position: relative;
                        &::after{
                            content: '';
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            width: 0%;
                            height: 2px;
                            background-color: #fff;
                            transition: all .3s ease-out;
                        }
                        &:hover{
                            opacity: 1;
                            &::after{
                                width: 100%;
                            }
                        }
                    }
                }
                .content{
                    font-size: 16px;
                    display: none;
                    // height: 0;
                    overflow: hidden;
                    transition: all .3s ease-out;
                    text-transform: uppercase;
                    white-space: pre-wrap !important;
                    p{
                        margin: 0;
                        font-size: 16px;
                        line-height: 18px !important;
                        opacity: .6;
                        white-space: pre-wrap !important;
                    }
                }
                .visible{
                    // display: block;
                    height: auto;
                    transition: all .3s ease-out;
                }
                .title-visible{
                    opacity: 1;
                }
            }
            
            .video-controllers{
                opacity: 0;
                bottom: 20px;
                width: 60%;
                padding: 10px 10px;
                border-radius: 2px;
                left: 20%;
                position: absolute;
                z-index: 9;
                display: flex;
                align-items: center;
                background: rgba(0, 0, 0, 0.226);
                backdrop-filter: blur( 13px );
                -webkit-backdrop-filter: blur( 13px );
                border-radius: 10px;
                transition: all .3s ease-out;
                @media all and (max-width: 780px){
                    opacity: .5;
                    z-index: 9999;
                    width: 90%;
                    margin: 0px 3%;
                    left: 0;
                    bottom: 100px;
                    padding: 5px;
                }
                .play{
                    width: 10%;
                    margin: 0px;
                    text-align: center;
                    
                }
                .volume{
                    width: 10%;
                    margin: 0px;

                }
                .timestamp{
                    width: 10%;
                    font-size: 10px;
                    margin: 0px;
                }
                .fullscreen{
                    width: 10%;
                    margin: 0px;
                    text-align: center;
                }
                .video-range{
                    width: 65%;
                    input{
                        display: block;
                        width: 100%;
                        -webkit-appearance: none;
                        vertical-align: middle;
                        outline: none;
                        border: none;
                        padding: 0;
                        background: none;
                        opacity: .8;
                        &::-webkit-slider-runnable-track {
                            background-color: #606670;
                            border-radius: 10px;
                            height: 1px;
                        }
                        &::-moz-range-track {
                            background-color: #606670;
                            border: none;
                        }
                        &::-ms-fill-lower { 
                            background-color: #606670;
                        }
                        &::-ms-fill-upper { 
                            background-color: #606670;
                        }
                        &::-webkit-slider-thumb {
                            background-color: #aaa;
                            transform: translate(0%, -50%);
                        }
                        &::-moz-range-thumb {
                            background-color: #aaa;
                        }
                        &::-ms-thumb { 
                            background-color: #aaa;
                        }
                    }
                }
            }

            &:hover{
                .video-controllers{
                    opacity: 1;
                }
            }
        }
        .content{
            width: 30%;
            @media all and (max-width: 780px){
                width: 100%;
                padding: 10px;
            }
        }
        p{
            white-space: normal;
            font-size: 20px;
            font-weight: 100;
            opacity: .8;
            line-height: 25px;
            @media all and (max-width: 780px){
                font-size: 14px;
            }
        }
        transition: all 1s ease-out;
    }
    .fullscreenVideo{
        display: block;
        width: 100vw;
        height: 100vh;
        .video{
            width: 100%;
            height: 100%;
            .video-controllers{
                bottom: 30px;
            }
        }
    }
    position: relative;
    .title{
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        text-align: center;
        height: 18vh;
        text-transform: uppercase;
        font-size: 7rem;
        margin: 0px;
        color: white;
        @media all and (max-width: 780px){
            position: relative;
            margin-top: 50px;
            margin-bottom: 50px;
            font-size: 3rem;
        }
    }
}

// Directors

.directors{
    display: flex;
    background-position: center center;
    background-size: cover;
    min-height: 100vh;
    min-width: 100vw;
    // align-items: center;
    justify-content: center;
    overflow-x: hidden;
    overflow-y: hidden;
    @media all and (max-width: 780px){
        display: block;
        padding-left: 0px;
        max-width: 100vw;
    }
   .page-title{
       padding: 50px 80px;
       min-width: 30vw;
       padding-top: 100px;
       @media all and (max-width: 780px){
        // width: 100%;
        padding: 50px 30px;
       }
       h1{
           color: white;
           font-size: 100px;
           margin: 0px;
           font-weight: 500;
           text-transform: uppercase;
           @media all and (max-width: 780px){
                font-size: 50px;
           }
       }
   } 

   .directors-name{
        padding: 100px 0px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        .name{
            width: 100% !important;
            margin: 5px 0px;
            color: white;
            font-size: 45px;
            text-transform: uppercase;
            font-weight: bold;
            text-align: center;
            opacity: .8;
            &:hover{
                opacity: 1;
            }
            cursor: none;
            a{
                color: white;
                text-decoration: none;
            }
            @media all and (max-width: 780px){
                 font-size: 22px;
                 margin: 3px 0px;
            }
            span{
                transition: all .3s ease-out;
                position: relative;
                &::after{
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 0%;
                    height: 2px;
                    background-color: #fff;
                    transition: all .3s ease-out;
                }
                &:hover{
                    opacity: 1;
                    &::after{
                        width: 100%;
                    }
                }
            }
        }
   }

   .director-bio{
        width: 30vw;
        color: white;
        display: none;
        p{
            white-space: normal;
        }
    }
   .cards{
       display: flex;
       @media all and (max-width: 780px){
            display: block;
       }
   }
   .card{
        cursor: none;
        background-image: url(../images/619cb1fa0ade2242152d104a_proud-to-be-freak.jpeg);
        background-size: cover;
        background-position: center;
        height: 100vh;
        padding: 10px;
        width: 320px;
        position: relative;
        @media all and (max-width: 780px){
             width: 100vw;
             height: 70vh;
        }
        &:nth-child(2){
            background-image: url(../images/6193ad66b0f40725f4e47b8e_contact-big.jpg);
        }
        &:nth-child(3){
            background-image: url(../images/619cb17585a3c9679ed6605b_start-the-buzz.jpeg);
        }
        &:nth-child(4){
            background-image: url(../images/619cb1debb2a7f6142dc00e0_woolmark.jpeg);
        }
        &::before{
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: #000;
            opacity: .2;
            z-index: 1;
        }
        transition: all .3s ease-in-out;
        &:hover{
            width: 550px;
            @media all and (max-width: 780px){
                 width: 100vw;
            }
        }
        .content{
            position: absolute;
            bottom: 60px;
            width: 400px;
            overflow: hidden;
            padding: 0px 20px;
            @media all and (max-width: 780px){
                 width: 100vw;
                 bottom: 20px;
            }
        }
        h3{
            position: relative;
            z-index: 4;
            color: white;
            font-size: 50px;
            line-height: 40px;
            text-transform: uppercase;
            margin: 0px;
            font-weight: 700;
            max-width: 350px;
            white-space: normal !important;
            @media all and (max-width: 780px){
                 font-size: 40px;
                 line-height: 40px;
            }
        }
        p{
            font-size: 24px;
            color: white;
            margin: 0px;
            text-transform: uppercase;
            width: 400px;
            white-space: normal !important;
        }
    }
}


// Works
.contactDesc{
    white-space: pre-line;
}
.contact{
    padding-left: 180px;
    min-height: 100vh;
    padding-top: 22vh;
    background-position: center center;
    background-size: cover;
    @media all and (max-width: 780px) {
        display: block;
        min-height: 130vh;
        padding-left: 0;
        overflow: scroll !important;
    }
   .page-title{
       padding: 50px 80px;
       width: 30vw;
       @media all and (max-width: 780px) {
           width: 100%;
           padding: 50px 10px;
       }
       h1{
           color: #fff;
           font-size: 100px;
           margin: 0px;
           font-weight: 600;
           text-transform: uppercase;
           @media all and (max-width: 780px) {
              font-size: 20px;
           }
       }
   } 
   .country{
    margin: 0px 5vw;
        @media all and (max-width: 780px) {
           display: block;
       }
       h5{
            font-weight: 600;
            font-size: 18px;
            color: #fafafa;
            margin: 0px;
            width: 70vw;
            padding-right: 10%;
            white-space: pre-line !important;
            opacity: .8;
            transition: all .1s ease-out;
            margin-bottom: 30px;
            &:hover{
                opacity: 1;
            }
            @media all and (max-width: 780px) {
                padding-right: 0;
                width: 90vw;
                font-size: 16px;
           }
       }
       h1{
           text-transform: uppercase;
           color: #fff;
           font-size: 100px;
           margin: 0px 0px 20px;
           line-height: 100px;
           opacity: .8;
           font-weight: 800;
           transition: all .1s ease-out;
           display: inline-block;
           font-family: 'LIQUIDO-Fluid';
           &:hover{
               font-family: 'LIQUIDO-Reguar';
           }
           &:hover{
               opacity: 1;
           }
           @media all and (max-width: 780px) {
                margin: 0px 0px 50px;
                font-size: 70px;
                line-height: 60px;
            }
       }
       .infos{
        display: flex;
            @media all and (max-width: 780px) {
            display: block;
        }
       }
       .contat-info{
           margin: 20px 0px;
           margin-right: 40px;
           h4{
               font-size: 26px;
                color: #fff;
                margin: 0px;
                opacity: .8;
                transition: all .1s ease-out;
                &:hover{
                    opacity: 1;
                }
           }
           p{
                color: #fff;
                font-weight: 300;
                opacity: .8;
                transition: all .1s ease-out;
                &:hover{
                    opacity: 1;
                }
           }
       }
   }
   .contact-details{
    display: flex;
        @media all and (max-width: 780px) {
            display: block;
        }
   }
}




.scroll-status{
    position: fixed;
    bottom: 50px;
    left: 0;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
    #scrollRange{
        border:  1px solid white;
        background-color: transparent;
        height: 20px;
        width: 220px;
        border-radius: 50px;
        opacity: .6;
        transition: all .3s ease-in-out;
        outline: none;
        -webkit-appearance: none;
        &:hover{
            opacity: 1;
        }
        &::-webkit-slider-thumb{
            height: 17px;
            width: 17px;
            cursor: pointer;
            background-color: #fff;
            border-radius: 50px;
            -webkit-appearance: none;
        }
    }
}

.row{
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 10px;
    .col-md-6{
        margin: 0px 15px;
        grid-column: span 6;
        @media all and (max-width: 780px) {
            grid-column: span 12;
        }
    }
}

.construction{
    position: fixed;
    background-image: url(../images/eb83d5d801f16eab225660ddd44d22d4.png);
    background-size: cover;
    background-position: center center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999999999999999999999999999999;
    @media all and (max-width: 780px) {
        overflow: scroll;
    }
    .container{
        display: flex;
        align-items: center;
        justify-content: center;
        .content{
            text-align: center;
            img{
                height: 40vh;
                @media all and (max-width: 780px) {
                    height: 20vh;
                    margin: 0px;
                }
            }
            h3{
                color: white;
                font-size: 28px;
            }
        }
        .country{
         margin: 20px 5vw;
         @media all and (max-width: 780px) {
            margin: 0;
         }
             @media all and (max-width: 780px) {
                display: block;
            }
            h5{
                 font-weight: 600;
                 font-size: 30px;
                 color: #fafafa;
                 margin: 0px;
                 width: 70vw;
                 padding-right: 10%;
                 white-space: normal !important;
                 opacity: .8;
                 transition: all .1s ease-out;
                 margin-bottom: 90px;
                 &:hover{
                     opacity: 1;
                 }
            }
            h6{
                font-weight: 900;
                font-size: 28px;
                color: #fff;
                margin: 0px;
                opacity: .8;
                transition: all .1s ease-out;
                &:hover{
                    opacity: 1;
                }
            }
            h1{
                text-transform: uppercase;
                color: #fff;
                font-size: 100px;
                margin: 0px 0px 20px;
                line-height: 100px;
                opacity: .8;
                font-weight: 800;
                transition: all .1s ease-out;
                display: inline-block;
                font-family: 'LIQUIDO-Fluid';
                &:hover{
                    font-family: 'LIQUIDO-Reguar';
                }
                &:hover{
                    opacity: 1;
                }
                @media all and (max-width: 780px) {
                     margin: 0px 0px 0px;
                     font-size: 70px;
                     line-height: 60px;
                 }
            }
            .infos{
             display: flex;
                 @media all and (max-width: 780px) {
                 display: block;
             }
            }
            .contat-info{
                margin: 20px 0px;
                margin-right: 40px;
                @media all and (max-width: 780px) {
                    margin-right: 0;
               }
                h4{
                    font-size: 26px;
                     color: #fff;
                     margin: 0px;
                     opacity: .8;
                     transition: all .1s ease-out;
                     &:hover{
                         opacity: 1;
                     }
                }
                p{
                     color: #fff;
                     font-weight: 300;
                     opacity: .8;
                     transition: all .1s ease-out;
                     &:hover{
                         opacity: 1;
                     }
                }
            }
        }
        .contact-details{
         display: flex;
             @media all and (max-width: 780px) {
                 display: block;
             }
        }
    }
}


.back{
    filter: invert(100);
    position: fixed;
    display: block;
    opacity: .7;
    right: 70px;
    top: 50px;
    height: 35px;
    z-index: 9999 !important;
    transition: all .3s ease-out;
    &:hover{
        opacity: 1;
    }
    @media all and (max-width: 780px) {
        right: 30px;
        top: 60px;
    }
    img{
        height: 100%;
    }
}


.locationContact{
    border-top: 2px solid #fff;
    opacity: .7;
    padding-top: 10px;
    margin-top: 20px;
    a{
        color: white;
        text-decoration: none;
    }
}

.backToTop{
    position: fixed;
    bottom: 50px;
    right: 50px;
    display: none;
    z-index: 9999999;
    @media all and (max-width: 780px) {
        display: block;
        }
        img{
            filter: invert(100);
            transform: rotate(90deg);
            width: 25px;
        }
}